<template>
  <ValidationObserver
    ref="userForm"
    v-slot="{ handleSubmit, failed }"
  >
    <b-toast
      :visible="failed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        v-slot="{ errors }"
        name="firstname"
        rules="required"
      >
        <b-form-group
          :disabled="!$allowPermission('agent:edit.username-and-bank-account')"
          :label="$t('user.firstname')"
          label-cols-lg="3"
          label-cols-sm="12"
          label-for="bank-firstname"
        >
          <b-form-input
            id="bank-firstname"
            v-model="playerBankAccountData.firstname"
            :state="errors[0] ? false : null"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="lastname"
        rules="required"
      >
        <b-form-group
          :disabled="!$allowPermission('agent:edit.username-and-bank-account')"
          :label="$t('user.lastname')"
          label-cols-lg="3"
          label-cols-sm="12"
          label-for="bank-lastname"
        >
          <b-form-input
            id="bank-lastname"
            v-model="playerBankAccountData.lastname"
            :state="errors[0] ? false : null"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="bankAccountNumber"
        rules="required"
      >
        <b-form-group
          :label="$t('bank_accounts.account_no')"
          label-cols-lg="3"
          label-cols-sm="12"
          label-for="bankAccountNumber"
        >
          <b-form-input
            id="bankAccountNumber"
            v-model="playerBankAccountData.bankAccountNumber"
            :disabled="!$allowPermission('agent:edit.username-and-bank-account')"
            :state="errors[0] ? false : null"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('bank_accounts.bank')"
        rules="required"
      >
        <b-form-group
          :label="`${$t('bank_accounts.bank')} *`"
          label-cols-lg="3"
          label-cols-sm="12"
          label-for="bankCode"
        >
          <b-form-select
            id="bankCode"
            v-model="playerBankAccountData.bankCode"
            :disabled="!$allowPermission('agent:edit.username-and-bank-account')"
            :state="errors[0] ? false : null"
          >
            <template #first>
              <b-form-select-option
                value=""
              >
                {{ `-- ${$t('fields.click_select')} --` }}
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="bank in thBanks"
              :key="bank.bank_code"
              :value="bank.bank_code"
            >
              {{ bank.bank_name_th }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </ValidationProvider>

      <div class="text-right">
        <b-button
          class="mr-2"
          variant="light"
          @click="setData()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-overlay
          :show="isUpdatingPlayer"
          class="d-inline-block"
          rounded
          spinner-small
        >
          <b-button
            block
            type="submit"
            variant="primary"
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {banks} from '../../utils/data-mock'
import {BankCodeEnum} from '@src/constants/bank';

export default {
  data() {
    return {
      playerBankAccountData: {
        bankCode: '',
        bankAccountNumber: '',
        firstname: '',
        lastname: '',
      },
    }
  },
  computed: {
    ...mapState({
      isUpdatingPlayer: (state) => state.player.isUpdatingPlayer,
    }),
    ...mapGetters(['playerDetail','isAgent']),
    thBanks() {
      return banks.filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL)
    },
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  watch: {
    playerDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    this.setData()
  },
  methods: {
    ...mapActions(['updatePlayer']),
    setData() {
      if (this.playerDetail && this.playerDetail.bankAccount) {
        const data = JSON.parse(JSON.stringify(this.playerDetail))
        if (data) {
          const { profile, bankAccount } = data;
          this.playerBankAccountData.bankCode = bankAccount.bankCode
          this.playerBankAccountData.bankAccountNumber = bankAccount.bankAccountNumber
          this.playerBankAccountData.firstname = profile.firstname || ''
          this.playerBankAccountData.lastname = profile.lastname || ''
        }
      } else {
        this.playerBankAccountData = {
          bankCode: '',
          bankAccountNumber: '',
          firstname: '',
          lastname: '',
        }
      }
    },
    onSubmit() {
      this.updatePlayer({ playerId: this.playerId, data: this.playerBankAccountData})
    },
  },
}
</script>
